import React, {useState} from 'react';
import {StaticImage} from "gatsby-plugin-image";
import {Trans} from "gatsby-plugin-react-i18next";
import Accordion from '../../../ui/legacy/Accordion/Accordion'

let dataSheetsKeypad = [
  {
    "title": "Curve | Smart | Keypad | Standard | Deadbolt | Shared or Assigned",
    "file": {
      "url": "https://assets.ctfassets.net/q5vaqi9g69zw/4i3qcuUnA8heKNp3tjybIz/69bbb3cc792137231fd8419b0a55aca9/DS-D6CKN-D-DEN.pdf",
      "fileName": "DS-D6CKN-D-DEN.pdf",
      "contentType": "application/pdf"
    }
  },
  {
    "title": "Curve | Smart | Keypad | Standard | Deadlatch | Assigned",
    "file": {
      "url": "https://assets.ctfassets.net/q5vaqi9g69zw/6ejakpUkSdDkJDdWzmh90V/b7328ef17f3450ea1049c1589f57d7b3/DS-D6CKN-P-DEN.pdf",
      "fileName": "DS-D6CKN-P-DEN.pdf",
      "contentType": "application/pdf"
    }
  }
];
let installGuidesKeypad = [
  {
    "title": "Curve | Smart | Keypad | Standard | Deadbolt or Deadlatch | Shared or Assigned",
    "file": {
      "url": "https://downloads.ctfassets.net/q5vaqi9g69zw/1E5ls5xk6TbKOhCGchkr61/0dd18c5e21f3957b4b9e196c9aac9423/IG-D6CN-KR-DEN.pdf",
      "fileName": "IG-D6CN-KR-DEN.pdf",
      "contentType": "application/pdf"
    }
  }
];
let productGuidesKeypad = [
  {
    "title": "Curve | Smart | Keypad | Standard | Deadbolt | Shared or Assigned",
    "file": {
      "url": "https://assets.ctfassets.net/q5vaqi9g69zw/2mjmaS2givDIli7WfuYF7B/c58ea09c9020676d36d158c646283360/PG-D6CKN-D-DEN.pdf",
      "fileName": "PG-D6CKN-D-DEN.pdf",
      "contentType": "application/pdf"
    }
  },
  {
    "title": "Curve | Smart | Keypad | Standard | Deadlatch | Assigned",
    "file": {
      "url": "https://assets.ctfassets.net/q5vaqi9g69zw/6o7yD979trExeBxfKUMEeZ/033839d1ce2123e9f66d955cac3e55e7/PG-D6CKN-P-DEN.pdf",
      "fileName": "PG-D6CKN-P-DEN.pdf",
      "contentType": "application/pdf"
    }
  }
];
let dataSheetsRFID = [
  {
    "title": "Curve | Smart | RFID | Standard | Deadbolt | Shared or Assigned",
    "file": {
      "url": "https://assets.ctfassets.net/q5vaqi9g69zw/41Mnl3lQgOjvmHCsZYIX4G/37d09bf4d56c54f6c01db8eb44d3e2e8/DS-D6CRN-D-DEN.pdf",
      "fileName": "DS-D6CRN-D-DEN.pdf",
      "contentType": "application/pdf"
    }
  },
  {
    "title": "Curve | Smart | RFID | Standard | Deadlatch | Assigned",
    "file": {
      "url": "https://assets.ctfassets.net/q5vaqi9g69zw/1g0baVpLu03b7DN3l0VBx4/77cee461175c4e335ce1f0421159a6ea/DS-D6CRN-P-DEN.pdf",
      "fileName": "DS-D6CRN-P-DEN.pdf",
      "contentType": "application/pdf"
    }
  }
];
let installGuidesRFID = [
  {
    "title": "Curve | Smart | RFID | Standard | Deadbolt or Deadlatch | Shared or Assigned",
    "file": {
      "url": "https://downloads.ctfassets.net/q5vaqi9g69zw/1E5ls5xk6TbKOhCGchkr61/0dd18c5e21f3957b4b9e196c9aac9423/IG-D6CN-KR-DEN.pdf",
      "fileName": "IG-D6CN-KR-DEN.pdf",
      "contentType": "application/pdf"
    }
  }
];
let productGuidesRFID = [
  {
    "title": "Curve | Smart | RFID | Standard | Deadbolt | Shared or Assigned",
    "file": {
      "url": "https://assets.ctfassets.net/q5vaqi9g69zw/6d3iPTO2grKl9hOjXgJJhz/55c50f2a0298c9f083c6e9eddde410df/PG-D6CRN-D-DEN.pdf",
      "fileName": "PG-D6CRN-D-DEN.pdf",
      "contentType": "application/pdf"
    }
  },
  {
    "title": "Curve | Smart | RFID | Standard | Deadlatch | Assigned",
    "file": {
      "url": "https://assets.ctfassets.net/q5vaqi9g69zw/lUVSqOXJGvlfQIxxeUQiE/f0a98cd09409e5b9bc2754ca57ed392e/PG-D6CRN-P-DEN.pdf",
      "fileName": "PG-D6CRN-P-DEN .pdf",
      "contentType": "application/pdf"
    }
  }
];

const Tab = (props) => {
  const [toggleState, setToggleState] = useState(1);
  const toggleTab = (index) => {
    setToggleState(index);
  };

  const getActiveClass = (index, className) => {
    return toggleState === index ? className : '';
  };

  return (
      <div className={'tab-container'}>
        <ul className="tab-list unstyled-list">
          <li
              className={getActiveClass(1, 'active')}
              onClick={() => toggleTab(1)}
          >
            Curve <Trans>network</Trans> <Trans>keypad</Trans>
          </li>
          <li
              className={getActiveClass(2, 'active')}
              onClick={() => toggleTab(2)}
          >
            Curve <Trans>network</Trans> <Trans>rfid</Trans>
          </li>
        </ul>
        <div className="tab-content-container">
          {/*Network Keypad*/}
          <div className={"tab-content " + getActiveClass(1, 'active')}>
            <div className="front-rear">
              <StaticImage
                  src={'./images/curve-keypad.png'}
                  loading={'lazy'}
                  width={600}
                  height={600}
                  quality={100}
                  formats={["auto", "webp", "avif"]}
                  alt="Curve Smart Lock"
              />
              <StaticImage
                  src={'../../../../images/shared/motorized-rear-units.png'}
                  loading={'lazy'}
                  width={466}
                  height={322}
                  quality={100}
                  formats={["auto", "webp", "avif"]}
                  alt="Rear Unit"
              />
            </div>
            <div className="lists">
              {/*DOCUMENTS*/}
              <div label="Document Library">
                <Accordion clsName={'next-support'}>
                  <div label="Data Sheets">
                    <div className="step-images">
                      <ul>
                        {
                          dataSheetsKeypad.map((doc, index) => (
                                  <li key={index}>
                                    <li key={index}>
                                      <a href={doc.file.url} target={'_blank'} rel={'noreferrer'}>{doc.title}</a>
                                    </li>
                                  </li>
                              )
                          )
                        }
                      </ul>
                    </div>
                  </div>
                  <div label="Install Guides">
                    <div className="step-images">
                      <ul>
                        {
                          installGuidesKeypad.map((doc, index) => (
                                  <li key={index}>
                                    <a href={doc.file.url} target={'_blank'} rel={'noreferrer'}>{doc.title}</a>
                                  </li>
                              )
                          )
                        }
                      </ul>
                    </div>
                  </div>
                  <div label="Product Guides">
                    <div className="step-images">
                      <ul>
                        {
                          productGuidesKeypad.map((doc, index) => (
                                  <li key={index}>
                                    <a href={doc.file.url} target={'_blank'} rel={'noreferrer'}>{doc.title}</a>
                                  </li>
                              )
                          )
                        }
                      </ul>
                    </div>
                  </div>
                </Accordion>
              </div>
            </div>
          </div>
          {/*Network RFID*/}
          <div className={"tab-content " + getActiveClass(2, 'active')}>
            <div className="front-rear">
              <StaticImage
                  src={'./images/curve-rfid.png'}
                  loading={'lazy'}
                  width={'600'}
                  height={'600'}
                  quality={100}
                  formats={["auto", "webp", "avif"]}
                  alt="Curve Smart Lock"
              />
              <StaticImage
                  src={'../../../../images/shared/motorized-rear-units.png'}
                  loading={'lazy'}
                  width={466}
                  height={322}
                  quality={100}
                  formats={["auto", "webp", "avif"]}
                  alt="Rear Unit"
              />
            </div>
            <div className="lists">
                {/*DOCUMENTS*/}
                <div label="Document Library">
                  <Accordion clsName={'next-support'}>
                    <div label="Data Sheets">
                      <div className="step-images">
                        <ul>
                          {
                            dataSheetsRFID.map((doc, index) => (
                                    <li key={index}>
                                      <li key={index}>
                                        <a href={doc.file.url} target={'_blank'} rel={'noreferrer'}>{doc.title}</a>
                                      </li>
                                    </li>
                                )
                            )
                          }
                        </ul>
                      </div>
                    </div>
                    <div label="Install Guides">
                      <div className="step-images">
                        <ul>
                          {
                            installGuidesRFID.map((doc, index) => (
                                    <li key={index}>
                                      <a href={doc.file.url} target={'_blank'} rel={'noreferrer'}>{doc.title}</a>
                                    </li>
                                )
                            )
                          }
                        </ul>
                      </div>
                    </div>
                    <div label="Product Guides">
                      <div className="step-images">
                        <ul>
                          {
                            productGuidesRFID.map((doc, index) => (
                                    <li key={index}>
                                      <a href={doc.file.url} target={'_blank'} rel={'noreferrer'}>{doc.title}</a>
                                    </li>
                                )
                            )
                          }
                        </ul>
                      </div>
                    </div>
                  </Accordion>
                </div>
            </div>
          </div>
        </div>
      </div>
  );
};

export default Tab;